import { useNavigate } from '@remix-run/react';
import { useState } from 'react';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import useSWRImmutable from 'swr/immutable';

import {
  type DtoUser,
  type ModelsLiveEventStudio,
  type ModelsSettingsLiveEvent,
} from '@lp-lib/api-service-client/public';

import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { useTitle } from '../../hooks/useTitle';
import { apiService } from '../../services/api-service';
import { type Venue } from '../../types';
import { err2s, makeTitle, uuidv4 } from '../../utils/common';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { useSettingsData } from '../DynamicSettings/hooks/useSettingsData';
import { DeleteIcon } from '../icons/DeleteIcon';
import { Loading } from '../Loading';

type FormData = Omit<ModelsSettingsLiveEvent, 'venueIds'> & { venues: Venue[] };

function AddUserModal(props: {
  type: 'Host' | 'Producer';
  onCancel: () => void;
  onConfirm: (user: DtoUser) => void;
}) {
  const [email, setEmail] = useState('');
  const [user, setUser] = useState<DtoUser | undefined>();

  const {
    call: search,
    state: { state, error },
  } = useLiveAsyncCall(async () => {
    const resp = await apiService.user.queryUsers({ email });
    if (resp.data.users.length === 0) return undefined;
    return resp.data.users[0];
  });

  const onSearch = async () => {
    if (!email) return;
    const u = await search();
    setUser(u);
  };

  return (
    <ModalWrapper containerClassName='w-100' borderStyle='gray'>
      <div className='px-5 py-5 flex flex-col gap-6'>
        <div className='text-xl font-bold'>Add New {props.type}</div>
        <div className='flex items-center justify-center gap-1'>
          <input
            type='text'
            className='field h-13 mb-0'
            placeholder='Search by email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && onSearch()}
            autoFocus
          />
          {state.isRunning && <Loading text='' />}
        </div>
        {error && <div className='text-sms text-red-002'>{err2s(error)}</div>}
        {user && (
          <div className='text-sms'>
            <p>
              <b>Uid:</b> {user.id}
            </p>
            <p>
              <b>Name:</b> {displayName(user)}
            </p>
          </div>
        )}
        <div className='flex items-center justify-center mt-auto gap-1'>
          <div className='flex items-center gap-2'>
            <button
              type='button'
              className='w-37.5 h-10 btn-secondary'
              onClick={props.onCancel}
            >
              Cancel
            </button>
            <button
              type='button'
              className='w-37.5 h-10 btn-primary flex items-center justify-center gap-2'
              disabled={state.isRunning || !user}
              onClick={() => {
                if (!user) return;
                props.onConfirm(user);
              }}
            >
              <div>Add</div>
            </button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
}

function AddStudioModal(props: {
  onCancel: () => void;
  onConfirm: (studio: ModelsLiveEventStudio) => void;
}) {
  const [name, setName] = useState('');
  const [calendarId, setCalendarId] = useState('');

  return (
    <ModalWrapper containerClassName='w-100' borderStyle='gray'>
      <div className='px-5 py-5 flex flex-col gap-6'>
        <div className='text-xl font-bold'>Add New Studio</div>
        <div className='flex flex-col items-center justify-center gap-1'>
          <input
            type='text'
            className='field h-13 mb-0'
            placeholder='Name'
            value={name}
            onChange={(e) => setName(e.target.value)}
            autoFocus
          />
          <input
            type='text'
            className='field h-13 mb-0'
            placeholder='Calendar ID'
            value={calendarId}
            onChange={(e) => setCalendarId(e.target.value)}
          />
        </div>
        <div className='flex items-center justify-center mt-auto gap-1'>
          <div className='flex items-center gap-2'>
            <button
              type='button'
              className='w-37.5 h-10 btn-secondary'
              onClick={props.onCancel}
            >
              Cancel
            </button>
            <button
              type='button'
              className='w-37.5 h-10 btn-primary flex items-center justify-center gap-2'
              disabled={!name || !calendarId}
              onClick={() => {
                if (!name || !calendarId) return;
                props.onConfirm({
                  id: uuidv4(),
                  name,
                  calendarId,
                });
              }}
            >
              <div>Add</div>
            </button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
}

function AddVenueModal(props: {
  onCancel: () => void;
  onConfirm: (venue: Venue) => void;
}) {
  const [vid, setVid] = useState('');
  const [venue, setVenue] = useState<Venue | undefined>();

  const {
    call: search,
    state: { state, error },
  } = useLiveAsyncCall(async () => {
    const resp = await apiService.venue.getVenueById(vid);
    return resp.data.venue;
  });

  const onSearch = async () => {
    if (!vid) return;
    const v = await search();
    setVenue(v);
  };

  return (
    <ModalWrapper containerClassName='w-100' borderStyle='gray'>
      <div className='px-5 py-5 flex flex-col gap-6'>
        <div className='text-xl font-bold'>Add New Venue</div>
        <div className='flex items-center justify-center gap-1'>
          <input
            type='text'
            className='field h-13 mb-0'
            placeholder='Search by email'
            value={vid}
            onChange={(e) => setVid(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && onSearch()}
            autoFocus
          />
          {state.isRunning && <Loading text='' />}
        </div>
        {error && <div className='text-sms text-red-002'>{err2s(error)}</div>}
        {venue && (
          <div className='text-sms'>
            <p>
              <b>Venue ID:</b> {venue.id}
            </p>
            <p>
              <b>Venue Name:</b> {venue.name ?? 'N/A'}
            </p>
          </div>
        )}
        <div className='flex items-center justify-center mt-auto gap-1'>
          <div className='flex items-center gap-2'>
            <button
              type='button'
              className='w-37.5 h-10 btn-secondary'
              onClick={props.onCancel}
            >
              Cancel
            </button>
            <button
              type='button'
              className='w-37.5 h-10 btn-primary flex items-center justify-center gap-2'
              disabled={state.isRunning || !venue}
              onClick={() => {
                if (!venue) return;
                props.onConfirm(venue);
              }}
            >
              <div>Add</div>
            </button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
}

function displayName(user: DtoUser) {
  if (user.organizer) {
    if (user.organizer.firstName && user.organizer.lastName) {
      return `${user.organizer.firstName} ${user.organizer.lastName}`.trim();
    } else if (user.organizer.firstName) {
      return user.organizer.firstName;
    }
  }
  return user.username;
}

function HostManage() {
  const { control, formState } = useFormContext<FormData>();
  const array = useFieldArray({
    control: control,
    name: 'hosts',
    rules: {
      required: {
        value: true,
        message: 'At least one is required',
      },
    },
  });
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const onAdd = () => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <AddUserModal
          type='Host'
          onCancel={p.internalOnCancel}
          onConfirm={(user) => {
            array.append({
              uid: user.id,
              email: user.email,
              name: displayName(user),
              active: true,
            });
            p.internalOnConfirm();
          }}
        />
      ),
    });
  };
  return (
    <div className='flex flex-col gap-2'>
      <div className='flex items-center gap-6'>
        <div className='text-xl font-bold'>Hosts</div>
        <button
          type='button'
          className='text-sm text-primary underline'
          onClick={onAdd}
        >
          Add New
        </button>
      </div>
      <table>
        <thead>
          <tr>
            <th className='text-left'>Uid</th>
            <th className='text-left'>Name</th>
            <th className='text-left'>Email</th>
            <th className='text-left'>Active</th>
            <th className='text-left'></th>
          </tr>
        </thead>
        <tbody className='text-sms'>
          {array.fields.map((p, index) => (
            <tr key={p.uid}>
              <td>{p.uid}</td>
              <td>{p.name}</td>
              <td>{p.email}</td>
              <td>{p.active ? 'Active' : 'Inactive'}</td>
              <td>
                <button
                  type='button'
                  className='btn w-8 h-8 rounded-xl bg-secondary 
                  flex items-center justify-center text-red-002'
                  onClick={() => array.remove(index)}
                >
                  <DeleteIcon />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {formState.errors.hosts?.root && (
        <div className='text-red-002 text-sms'>
          {formState.errors.hosts?.root.message}
        </div>
      )}
    </div>
  );
}

function ProducerManage() {
  const { control, formState } = useFormContext<FormData>();
  const array = useFieldArray({
    control: control,
    name: 'producers',
    rules: {
      required: {
        value: true,
        message: 'At least one is required',
      },
    },
  });
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const onAdd = () => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <AddUserModal
          type='Producer'
          onCancel={p.internalOnCancel}
          onConfirm={(user) => {
            array.append({
              uid: user.id,
              email: user.email,
              name: displayName(user),
              active: true,
            });
            p.internalOnConfirm();
          }}
        />
      ),
    });
  };
  return (
    <div className='flex flex-col gap-2'>
      <div className='flex items-center gap-6'>
        <div className='text-xl font-bold'>Producers</div>
        <button
          type='button'
          className='text-sm text-primary underline'
          onClick={onAdd}
        >
          Add New
        </button>
      </div>
      <table>
        <thead>
          <tr>
            <th className='text-left'>Uid</th>
            <th className='text-left'>Name</th>
            <th className='text-left'>Email</th>
            <th className='text-left'>Active</th>
            <th className='text-left'></th>
          </tr>
        </thead>
        <tbody className='text-sms'>
          {array.fields.map((p, index) => (
            <tr key={p.uid}>
              <td>{p.uid}</td>
              <td>{p.name}</td>
              <td>{p.email}</td>
              <td>{p.active ? 'Active' : 'Inactive'}</td>
              <td>
                <button
                  type='button'
                  className='btn w-8 h-8 rounded-xl bg-secondary 
                  flex items-center justify-center text-red-002'
                  onClick={() => array.remove(index)}
                >
                  <DeleteIcon />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {formState.errors.producers?.root && (
        <div className='text-red-002 text-sms'>
          {formState.errors.producers?.root.message}
        </div>
      )}
    </div>
  );
}

function StudioManage() {
  const { control, formState } = useFormContext<FormData>();
  const array = useFieldArray({
    control: control,
    name: 'studios',
    rules: {
      required: {
        value: true,
        message: 'At least one is required',
      },
    },
  });
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const onAdd = () => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <AddStudioModal
          onCancel={p.internalOnCancel}
          onConfirm={(studio) => {
            array.append(studio);
            p.internalOnConfirm();
          }}
        />
      ),
    });
  };
  return (
    <div className='flex flex-col gap-2'>
      <div className='flex items-center gap-6'>
        <div className='text-xl font-bold'>Studios</div>
        <button
          type='button'
          className='text-sm text-primary underline'
          onClick={onAdd}
        >
          Add New
        </button>
      </div>
      <table>
        <thead>
          <tr>
            <th className='text-left'>Name</th>
            <th className='text-left'>Calendar</th>
            <th className='text-left'></th>
          </tr>
        </thead>
        <tbody className='text-sms'>
          {array.fields.map((p, index) => (
            <tr key={p.calendarId}>
              <td>{p.name}</td>
              <td>{p.calendarId}</td>
              <td>
                <button
                  type='button'
                  className='btn w-8 h-8 rounded-xl bg-secondary 
                  flex items-center justify-center text-red-002'
                  onClick={() => array.remove(index)}
                >
                  <DeleteIcon />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {formState.errors.studios?.root && (
        <div className='text-red-002 text-sms'>
          {formState.errors.studios?.root.message}
        </div>
      )}
    </div>
  );
}

function VenueManage() {
  const { control, formState } = useFormContext<FormData>();
  const array = useFieldArray({
    control: control,
    name: 'venues',
    rules: {
      required: {
        value: true,
        message: 'At least one is required',
      },
    },
  });
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const onAdd = () => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <AddVenueModal
          onCancel={p.internalOnCancel}
          onConfirm={(venue) => {
            array.append(venue);
            p.internalOnConfirm();
          }}
        />
      ),
    });
  };
  return (
    <div className='flex flex-col gap-2'>
      <div className='flex items-center gap-6'>
        <div className='text-xl font-bold'>Venues</div>
        <button
          type='button'
          className='text-sm text-primary underline'
          onClick={onAdd}
        >
          Add New
        </button>
      </div>
      <table>
        <thead>
          <tr>
            <th className='text-left'>Venue ID</th>
            <th className='text-left'>Name</th>
            <th className='text-left'></th>
          </tr>
        </thead>
        <tbody className='text-sms'>
          {array.fields.map((p, index) => (
            <tr key={p.id}>
              <td>{p.id}</td>
              <td>{p.name ?? 'N/A'}</td>
              <td>
                <button
                  type='button'
                  className='btn w-8 h-8 rounded-xl bg-secondary 
                  flex items-center justify-center text-red-002'
                  onClick={() => array.remove(index)}
                >
                  <DeleteIcon />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {formState.errors.venues?.root && (
        <div className='text-red-002 text-sms'>
          {formState.errors.venues?.root.message}
        </div>
      )}
    </div>
  );
}

function LiveEventSettingsEditor(props: {
  settings: ModelsSettingsLiveEvent | undefined;
  venues: Venue[];
}) {
  const { settings, venues } = props;
  const form = useForm<FormData>({
    defaultValues: {
      hosts: settings?.hosts ?? [],
      studios: settings?.studios ?? [],
      producers: settings?.producers ?? [],
      venues,
    },
  });
  const { updateValue } = useSettingsData('live-event');
  const { isSubmitting, isDirty } = form.formState;
  const navigate = useNavigate();
  const onSubmit = form.handleSubmit(async (data) => {
    await Promise.all([
      updateValue({ key: 'hosts', value: data.hosts }),
      updateValue({ key: 'producers', value: data.producers }),
      updateValue({ key: 'studios', value: data.studios }),
      updateValue({ key: 'venueIds', value: data.venues.map((v) => v.id) }),
    ]);
    navigate('/admin/toolkit/events/list');
  });
  return (
    <div className='w-full h-full px-10 pb-10'>
      <FormProvider {...form}>
        <form className='text-white flex flex-col gap-10' onSubmit={onSubmit}>
          <HostManage />
          <ProducerManage />
          <StudioManage />
          <VenueManage />
          <button
            type='submit'
            className='btn-primary w-40 h-10 flex items-center justify-center gap-2'
            disabled={isSubmitting || !isDirty}
          >
            {isSubmitting && <Loading text='' />}
            {isDirty ? 'Save' : 'No Changes'}
          </button>
        </form>
      </FormProvider>
    </div>
  );
}

function useLiveVenues() {
  return useSWRImmutable(
    '/events/live-venues',
    async () => (await apiService.event.liveVenues()).data
  );
}

export function LiveEventSettings() {
  useTitle(makeTitle('Live Event Settings'));
  const swr1 = useSettingsData('live-event');
  const swr2 = useLiveVenues();
  if (swr1.isLoading || swr2.isLoading) return <Loading />;
  return (
    <LiveEventSettingsEditor
      settings={swr1.data}
      venues={swr2.data?.venues ?? []}
    />
  );
}
