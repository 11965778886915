import { LiveEventSettings } from '../components/Event';

function LiveEventSettingsRoute() {
  return <LiveEventSettings />;
}

export const Component = LiveEventSettingsRoute;

export function clientLoader() {
  return null;
}
